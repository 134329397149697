import { Service } from './service'

const PATH = 'user/whatsapp/'


class WhatsappClientService extends Service {
  addClient (params = {}) {
    return this.post(PATH + 'add', params)
  }
}

export default new WhatsappClientService()

