export default {
  name: 'Button360Dialog',
  props: {
    partnerId: {
      type: String,
      required: true,
      default: null,
    },
    requestedNumber: {
      type: String,
      required: false,
    },
    label: {
      type: String,
      required: false,
    },
    env: {
      type: String,
      required: false,
      validator (value) {
        // The value must match one of these strings
        return ['local', 'staging', 'rc', 'prod'].includes(value)
      },
    },
    queryParameters: {
      type: Object,
      required: false,
    },
    buttonText: {
      type: String,
      required: true,
    },
  },
  data () {
    return {
      baseUrl: null,
      permissionsUrl: 'https://hub.360dialog.com/dashboard/app/pey7ilPA/permissions',
      windowObjectReference: null,
      previousUrl: null,
    }
  },
  created () {
    this.baseUrl = this.setBaseUrl()
    this.permissionsUrl = this.setPermissionUrl()
    this.setPermissionUrlQueryParameters()
  },
  mounted () {
    const params = this.$route.query
    const client = this.getUrlParameter(params, 'client')
    const channels = this.getUrlParameter(params, 'channels')
    const revokedChannels = this.getUrlParameter(params, 'revoked')

    if (client && channels) {
      const channelsArray = channels
        .slice(1, channels.length - 1)
        .split(',').filter(s => s)

      const callbackObj = {
        partner: this.partnerId,
        client: client,
        channels: channelsArray,
      }

      if (revokedChannels) {
        const revokedChannelsArray = revokedChannels
          .substring(1, revokedChannels.length - 1)
          .split(',')
        callbackObj.revokedChannels = revokedChannelsArray
      }

      console.log('Button 360 dialog received callback', callbackObj)

      this.$emit('callback', callbackObj)

      // remove search parameters from URL after fetching them
      // window.history.replaceState(null, "", window.location.pathname);

      this.cleanQueryString()
    }

    // send to parent (opener) window and close small window
    // console.log(window.opener)
    if (window.opener) {
      // console.log('openers esiten')
      window.opener.postMessage(params)
      window.close()
    }
  },
  methods: {
    cleanQueryString () {
      let qString = ''
      if (this.$route.query) {
        qString = this.generateQueryStringFromQueryParams(this.$route.query)
      }
      const queryString = qString.length ? qString + '&' : ''
      const path = this.$router.currentRoute.path + (queryString.length ? '?' + queryString : '')
      this.$router.push({ path }).catch(() => {})
    },
    setBaseUrl () {
      return (this.env === 'local' && 'http://0.0.0.0:8082') ||
        (this.env === 'staging' && 'https://staging-admin.360dialog.io') ||
        (this.env === 'rc' && 'https://rc-admin.360dialog.io') ||
        'https://hub.360dialog.com'
    },
    setPermissionUrl () {
      return this.requestedNumber
        ? `${this.baseUrl}/dashboard/app/${ this.partnerId }/permissions?number=${ this.requestedNumber }`
        : `${this.baseUrl}/dashboard/app/${ this.partnerId }/permissions`
    },
    setPermissionUrlQueryParameters () {
      if (this.queryParameters) {
        const values = Object.values(this.queryParameters)
        Object.keys(this.queryParameters).forEach((k, idx) => {
          if (idx === 0 && !this.requestedNumber) {
            this.permissionsUrl = this.permissionsUrl + `?${k}=${values[idx]}`
          } else {
            this.permissionsUrl = this.permissionsUrl + `&${k}=${values[idx]}`
          }
        })
        this.permissionsUrl = encodeURI(this.permissionsUrl)
      }
    },
    getUrlParameter (params, name) {
      params = '?' + new URLSearchParams(params).toString()
      name = name.replace(/[[]/, '\\[').replace(/[\]]/, '\\]')
      const regex = new RegExp('[\\?&]' + name + '=([^&#]*)')
      const results = regex.exec(params)
      return results === null
        ? ''
        : decodeURIComponent(results[1].replace(/\+/g, ' '))
    },
    openPermissions () {
      console.log('openPermissionsUrl', this.permissionsUrl)
      this.openSignInWindow(this.permissionsUrl, 'connect-360dialog', window.location.origin)
    },

    openSignInWindow (url, name, baseUrl) {
      // remove any existing event listeners
      window.removeEventListener('message', this.receiveMessage)

      // window features
      const strWindowFeatures = 'toolbar=no, menubar=no, width=600, height=900, top=100, left=100'

      if (this.windowObjectReference === null || this.windowObjectReference.closed) {
        /* if the pointer to the window object in memory does not exist
          or if such pointer exists but the window was closed */
          this.windowObjectReference = window.open(url, name, strWindowFeatures)
      } else if (this.previousUrl !== url) {
        /* if the resource to load is different,
          then we load it in the already opened secondary window and then
          we bring such window back on top/in front of its parent window. */
          this.windowObjectReference = window.open(url, name, strWindowFeatures)
          this.windowObjectReference.focus()
      } else {
        /* else the window reference must exist and the window
          is not closed; therefore, we can bring it back on top of any other
          window with the focus() method. There would be no need to re-create
          the window or to reload the referenced resource. */
          this.windowObjectReference.focus()
      }

      // add the listener for receiving a message from the popup
      window.addEventListener(
        'message',
        (event) => this.receiveMessage(event, baseUrl),
        false,
      )
      // assign the previous URL
      this.previousUrl = url
    },

    receiveMessage (event, baseUrl) {
      if (event.origin !== baseUrl) {
        return
      }

      // --------- On localhost avoid vue-devtools/webpack messaging ---------
      if (['vue-devtools-proxy', 'vue-devtools-backend'].includes(event.data.source)) {
        return
      }
      if ((typeof event.data === 'string') && event.data.indexOf('webpackHotUpdate') > -1) {
        return
      } else if (['webpackInvalid', 'webpackWarnings'].includes(event.data.type)) {
        return
      }
      // -------------------------------------------------------------------

      console.log('Message received (event, data): ', event, data)
      const { data } = event
      const redirectUrl = `${data.substring(1)}`
      // window.location.search = redirectUrl

      let qString = ''
      if (this.$route.query) {
        qString = this.generateQueryStringFromQueryParams(this.$route.query)
      }
      const queryString = (qString.length ? qString + '&' : '') + redirectUrl
      const path = this.$router.currentRoute.path + '?' + queryString
      this.$router.push({ path }).catch(() => {})
      this.$router.go()
    },

    generateQueryStringFromQueryParams (qParams) {
      let queryString = ''
      Object.keys(qParams).forEach((k, idx) => {
        if (!['client', 'channels', 'revoked'].includes(k)) {
          if (idx === 0) {
            queryString += `${k}=${qParams[k]}`
          } else {
            queryString += `&${k}=${qParams[k]}`
          }
        }
      })

      return queryString
    },
  },
}
